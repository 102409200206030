<template>
  <v-container
     id="shipments"
     fluid
     tag="section"
     class="shipments-container pa-0 pa-md-3"
   >
   <!-- Photos Dialog -->
   <v-dialog
     v-model="dialog"
     max-width="600"
     content-class="photos-dialog"
     :fullscreen="$vuetify.breakpoint.xsOnly"
   >
     <v-card v-if="parcel" rounded="lg" elevation="3">
       <v-card-title class="dialog-header">
         <div class="d-flex align-center">
           <v-icon color="primary" class="mr-2">mdi-camera</v-icon>
           <span class="text-h6 font-weight-medium">Fulfillment Photos: {{ parcel.name }}</span>
         </div>
         <v-spacer></v-spacer>
         <v-btn
           icon
           @click="dialog = false"
         >
           <v-icon>mdi-close</v-icon>
         </v-btn>
       </v-card-title>
       
       <v-card-text class="dialog-content text-center">
         <p class="text-h6 font-weight-medium mb-4">
           Fulfillment should contain:
         </p>
         <div class="item-list mb-4">
           <p v-for="(item, index) in parcel.line_items" :key="`item-${index}`" class="text-body-2 my-1">
             {{ item.quantity }}x {{ item.name }}
           </p>
         </div>
         
         <v-carousel 
           v-model="photo_index"
           height="400"
           hide-delimiter-background
           show-arrows-on-hover
         >
           <v-carousel-item
             v-for="(photo, index) in photos"
             :src="photo"
             :key="index"
             contain
           />
         </v-carousel>
       </v-card-text>
     </v-card>
   </v-dialog>
     <div class="shipments-content">
       <!-- Header with title and icon -->
       <!-- <div class="shipments-header mb-4">
         <h1 class="text-h5 font-weight-medium">
           <v-icon color="primary" class="mr-2">mdi-package-variant-closed</v-icon>
           Shipments
           
         </h1>
       </div> -->
       
       <!-- Search and filter controls -->
       <v-card rounded="lg" elevation="2" class="mb-4 pa-4">
         <v-row>
           <v-col class="grow">
             <v-text-field
               v-model="search_query"
               label="Search fulfillments, customers, or tracking numbers"
               prepend-icon="mdi-magnify"
               clearable
               outlined
               dense
               hide-details
               @input="debounced_search"
               @click:clear="clearSearch"
               :color="search_query ? 'primary' : ''"
               class="search-field"
             ></v-text-field>
           </v-col>
           <v-col class="shrink"
            v-if="company.data_sources && company.data_sources.length > 0"
            >
             <v-select
               :items="company.data_sources"
               v-model="selected_data_source_id"
               @change="change_store"
               outlined
               dense
               hide-details
               label="Store"
               item-value="id"
               item-text="name"
               class="store-selector"
             />
           </v-col>
         </v-row>
         
         <v-row v-if="search_query" class="mt-2">
           <v-col>
             <v-chip
               color="primary"
               close
               @click:close="clearSearch"
               class="search-chip"
             >
               <v-icon left small>mdi-filter</v-icon>
               Searching: {{ search_query }} ({{ get_active_fulfillments.length }} results)
             </v-chip>
           </v-col>
         </v-row>
       </v-card>
       <!-- Loading indicator -->
       <div v-if="loading" class="loading-container">
         <v-progress-circular
           indeterminate
           :size="64"
           width="5"
           color="primary"
           class="mb-4"
         ></v-progress-circular>
         <div class="text-body-1 grey--text text--darken-1">
           Loading fulfillments...
         </div>
       </div>
       
       <!-- Data table -->
       <v-card
         rounded="lg"
         elevation="2"
         class="data-table-card"
         v-else
       >
         <v-data-table
           :headers="headers"
           :items="computed_items"
           item-key="name"
           :options.sync="options"
           :items-per-page="10"
           :footer-props="footer_props"
           :server-items-length="search_query ? get_active_fulfillments.length : get_server_fulfillments"
           no-data-text="No fulfillments have yet been done"
           no-results-text="No results found for your search"
           :search="search"
           :loading="loading"
           class="fulfillments-table"
         >
         <!-- <template v-slot:top>
           <v-text-field
             v-model="search"
             label="Search"
             class="mx-4"
           ></v-text-field>
         </template> -->
         <template v-slot:[`item.created_at`]="{ item }">
           {{ item.created_at | DateFilter }}
         </template>
         <template v-slot:[`item.sf_photo_refs`]="{ item }">
           <v-btn
             icon
             @click="open_dialog(item)"
             v-if="item.sf_photo_refs && item.sf_photo_refs.length"
             >
               <v-icon>
                 mdi-camera-outline
               </v-icon>
           </v-btn>
         </template>
         <template v-slot:[`item.sf_label_refs`]="{ item }">
         <v-menu 
           offset-y
           >
         <template
           v-slot:activator="{ on, attrs }"
           >
           <v-btn
             small
             icon
             v-bind="attrs"
             v-on="on"
             v-show="item.sf_label_refs.length"
           >
             <v-icon>
               mdi-printer-outline
             </v-icon>
           </v-btn>
         </template>
         <v-list
           dense
           >
           <v-list-item-group
             v-for="(label, i) in item.sf_label_refs"
             :key="`label-${i}`"
             >
                   <v-list-item
                     @click="sendToPrint({pdf_ref: label.ref, description: label.description, media: label.media, sf_carrier_account_id: label.sf_carrier_account_id })"
                     v-if="company.printserver_id && label.sf_carrier_account_id"
                     :disabled="!get_printing_possible || !get_printserver_connected"
                     >
                   <v-list-item-icon>
                     <v-icon>mdi-printer-wireless</v-icon>
                   </v-list-item-icon>
                   <v-list-item-content>Resend to printer</v-list-item-content>
                 </v-list-item>
                 
                 <v-list-item @click="openPrint(label.ref)">
                   <v-list-item-icon>
                     <v-icon>mdi-open-in-new</v-icon>
                   </v-list-item-icon>
                   <v-list-item-content>Open in new tab</v-list-item-content>
                 </v-list-item>
                 
                 <v-list-item @click="printWithDialog(label.ref)">
                   <v-list-item-icon>
                     <v-icon>mdi-printer</v-icon>
                   </v-list-item-icon>
                   <v-list-item-content>Print</v-list-item-content>
                 </v-list-item>
           </v-list-item-group>
         </v-list>
         </v-menu>
         </template>
         <template v-slot:[`item.tracking_number`]="{ item }">
           <a v-if="item.tracking_url" :href="item.tracking_url" target="new">{{ item.tracking_number  }}</a>
           <span v-else-if="item.tracking_number">{{ item.tracking_number }}</span>
         </template>
         <!-- <template v-slot:[`footer.prepend`]>
           <p>TJENA</p>
         </template> -->
       </v-data-table>
       </v-card>
     </div>
  </v-container>
 </template>
 <script>
 
 import { mapState, mapGetters } from 'vuex'
 import { mapFields } from 'vuex-map-fields'
 import { DateFilter } from '@/utils/filters'
 import firebase from 'firebase/app'
 import 'firebase/storage'
 
 export default {
   components: {
    
   },
   filters: {
     DateFilter
   },
   computed: {
     ...mapState({
       fulfillments: state => state.fulfillments,
       company: state => state.warehouse.company,
     }),
     ...mapGetters({
       get_active_fulfillments: 'fulfillments/get_active_fulfillments',
       get_server_fulfillments: 'fulfillments/get_server_fulfillments',
       get_printing_possible: 'warehouse/get_printing_possible',
       get_printserver_connected: 'warehouse/get_printserver_connected',
     }),
     ...mapFields([
       'fulfillments.selected_data_source_id',
     ]),
     computed_items(){
       const { page, itemsPerPage } = this.options
       return this.get_active_fulfillments.slice(
         (page - 1) * itemsPerPage, page * itemsPerPage
       )
     }
   },
  watch: {
    dialog: function(val){
      if(val) this.photo_index = 0
    },
    // selected_data_source_id: async function(v) {
    //   console.log('SELECTED', v)
    //   const val = this.options
      
    // },
    options: {
      async handler (val, oldVal) {
        // Only fetch data if this is not triggered by our own methods
        // Check if page or itemsPerPage changed
        if (!this.ignoreOptionsChange && 
            (oldVal.page !== val.page || 
             oldVal.itemsPerPage !== val.itemsPerPage)) {
          console.log('options changed', val);
          await this.get_data();
        }
      },
      deep: true
    }
  },
   data() {
     return {
       loading: false,
       search: '',
       search_query: '',
       search_timeout: null,
       options: {},
       ignoreOptionsChange: false,
       parcel: null,
       photos: [],
       photo_index: 0,
       dialog: false,
       footer_props: {
         'items-per-page-options': [
           5, 10, 25, 50, 100
         ]
       },
       headers: [
           {
             text: 'Fulfillment #',
             align: 'start',
             sortable: false,
             value: 'name',
           },
           // {
           //   text: 'Store',
           //   align: 'start',
           //   sortable: false,
           //   value: 'sf_ds_name',
           // },
           {
             text: 'Fulfilled at',
             align: 'start',
             sortable: false,
             value: 'created_at',
           },
           {
             text: 'Customer',
             align: 'start',
             sortable: false,
             value: 'shipping_address.name',
           },
           {
             text: 'Country',
             align: 'start',
             sortable: false,
             value: 'shipping_address.country_code',
           },
           {
             text: 'Photos',
             align: 'center',
             sortable: false,
             value: 'sf_photo_refs',
           },
           {
             text: 'Prints',
             align: 'center',
             sortable: false,
             value: 'sf_label_refs',
           },
           {
             text: 'Tracking',
             align: 'start',
             sortable: false,
             value: 'tracking_number',
           },
       ]
     }
   },
   methods: {
     debounced_search() {
       // Clear any existing timeout
       if (this.search_timeout) {
         clearTimeout(this.search_timeout);
       }
       
       // Set a new timeout to execute the search after 500ms
       this.search_timeout = setTimeout(() => {
         if (!this.search_query || this.search_query.trim() === '') {
           // If search is cleared, explicitly call clearSearch to reset the view
           this.clearSearch();
         } else {
           this.search_fulfillments();
         }
       }, 500);
     },
     
     async search_fulfillments() {
       try {
         this.ignoreOptionsChange = true;
         this.options.page = 1;
         this.loading = true;
         
         await this.$store.dispatch('fulfillments/search_parcels', { 
           query: this.search_query,
           data_source_id: this.selected_data_source_id
         });
       } catch (error) {
         console.error('Error searching fulfillments:', error);
       } finally {
         this.loading = false;
         this.ignoreOptionsChange = false;
       }
     },
     
     async clearSearch() {
       try {
         this.ignoreOptionsChange = true;
         this.search_query = '';
         this.options.page = 1;
         this.$store.commit('fulfillments/RESET_STATE');
         await this.get_data();
       } finally {
         this.ignoreOptionsChange = false;
       }
     },
     
     async change_store(){
       try {
         this.ignoreOptionsChange = true;
         this.options.page = 1;
         this.search_query = '';
         await this.get_data();
       } finally {
         this.ignoreOptionsChange = false;
       }
     },
     async get_data(){
       try {
         const val = this.options;
         this.loading = true;
         
         await this.$store.dispatch('fulfillments/fetch_parcels', { 
           rows: val.page * val.itemsPerPage > this.get_server_fulfillments ? 
             this.get_server_fulfillments :
             val.page * val.itemsPerPage
         });
       } finally {
         this.loading = false;
       }
     },
     async openPrint(file_ref){
       const url = await firebase.storage().ref().child(file_ref).getDownloadURL()
       window.open(url, 'new')
     },
     async printWithDialog(file_ref){
       const url = await firebase.storage().ref().child(file_ref).getDownloadURL()
       const xhr = new XMLHttpRequest();
       xhr.responseType = 'blob';
       xhr.onload = () => {
         const blob = xhr.response;
         print({ printable: URL.createObjectURL(blob) })
       };
       xhr.open('GET', url);
       xhr.send();
       print({ printable: url })
     },
     sendToPrint({ pdf_ref, description, media, sf_carrier_account_id }){ 
       this.$store.dispatch('warehouse/submit_print_job', { 
         pdf_ref, 
         description, 
         media, 
         carrier_id: sf_carrier_account_id 
       })
     },
   async open_dialog(item){
     console.log(item);
     this.parcel = item;
     this.dialog = true
     const photos = await Promise.all(
       item.sf_photo_refs.map(p => firebase.storage().ref().child(p.ref).getDownloadURL())
       )
     this.photos = photos
   }
   },
   async mounted(){
     // Initialize options with default values
     this.options = {
       page: 1,
       itemsPerPage: 10,
       sortBy: [],
       sortDesc: [],
       groupBy: [],
       groupDesc: [],
       multiSort: false,
       mustSort: false
     };
     
     this.selected_data_source_id = this.company.data_sources[0]?.id;
     
     // Initial data load
     try {
       this.ignoreOptionsChange = true;
       await this.get_data();
     } finally {
       this.ignoreOptionsChange = false;
     }
   }
 }
 </script>
 <style lang="scss" scoped>
 .shipments-container {
   background-color: #f6f6f7;
   min-height: 100vh;
   padding-bottom: 24px;
 }
 
 .shipments-content {
   animation: fadeIn 0.6s ease-out;
 }
 
 .shipments-header {
   padding: 16px 0 8px;
 }
 
 .loading-container {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   min-height: 300px;
   padding: 32px;
   
   .v-progress-circular {
     animation: pulse 2s infinite ease-in-out;
   }
 }
 
 .store-selector {
  min-width: 8em;
 }

 .store-selector,
 .search-field {
   transition: all 0.3s ease;
   
   &:hover {
     transform: translateY(-1px);
   }
 }
 
 .search-chip {
   font-weight: 500;
   transition: all 0.3s ease;
   
   &:hover {
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
   }
 }
 
 .data-table-card {
   overflow: hidden;
   
   .fulfillments-table {
     border-radius: 8px;
   }
 }
 
 /* Dialog styles */
 .dialog-header {
   background-color: #f9f9fa;
   border-bottom: 1px solid rgba(0, 0, 0, 0.05);
   padding: 16px 24px;
 }
 
 .dialog-content {
   padding: 24px;
 }
 
 .item-list {
   max-height: 150px;
   overflow-y: auto;
   background-color: #f9f9fa;
   border-radius: 8px;
   padding: 12px;
   border: 1px solid rgba(0, 0, 0, 0.05);
 }
 
 /* Animations */
 @keyframes fadeIn {
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
 }
 
 @keyframes pulse {
   0% {
     transform: scale(1);
   }
   50% {
     transform: scale(1.05);
   }
   100% {
     transform: scale(1);
   }
 }
 
 /* Mobile-specific styles */
 @media (max-width: 600px) {
   .shipments-container {
     padding: 0;
   }
   
   .shipments-header {
     padding: 16px 16px 8px;
   }
 }
 </style>
